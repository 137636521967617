import Routes, { type Route } from "~/constants/routes.constants";
import LogoTitle from "../assets/logo_title.svg";
import ListenNowBtn from "./UI/ListenNowBtn";
import NavLink from "./UI/NavLink";
import Link from "next/link";
import { useRouter } from "next/router";

interface NavbarProps {
  showLogo: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ showLogo }) => {
  const router = useRouter();

  return (
    <nav className="navbar flex flex-row justify-between md:justify-end md:px-12">
      <MobileNav
        links={Routes.links}
        className="flex flex-row gap-2 self-start md:hidden"
      />
      <div
        className="absolute left-5 top-4 z-50 hidden max-w-[300px] transition-opacity duration-300 md:block"
        style={{ opacity: showLogo ? "100%" : "0%" }}
      >
        <Link href="/" title="Home">
          <div className="cursor-pointer">
            <LogoTitle />
          </div>
        </Link>
      </div>

      <div
        className={`${
          showLogo
            ? "shadow-xl md:bg-base-100/50 md:backdrop-blur-sm"
            : "md:bg-base-100/0"
        } flex flex-row gap-8 rounded-xl transition-all duration-1000 md:mt-2 md:px-4 md:py-2`}
      >
        {Routes.links.map((link) => (
          <NavLink
            href={link.href}
            title={link.title}
            key={link.href}
            selected={router.pathname === `${link.href}`}
            className="hidden p-4 text-black hover:text-gray-800 md:block"
          />
        ))}
        <ListenNowBtn />
      </div>
    </nav>
  );
};

interface MobileNavProps {
  links: Route[];
  className: string;
}

const MobileNav: React.FC<MobileNavProps> = ({ links, className }) => {
  const router = useRouter();

  return (
    <div className={`navbar-start ${className}`}>
      {router.pathname !== "/" && (
        <Link
          href="/"
          className="btn-neutral btn-circle btn shadow-md shadow-black/50"
          title="Home"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 16 16"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M3.75 5.75v7.5h8.5v-7.5m-10.5 1.5L8 1.75l6.25 5.5"
            />
          </svg>
        </Link>
      )}
      <div className="dropdown">
        <label
          tabIndex={0}
          className="btn-neutral btn-circle btn shadow-md shadow-black/50"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 16 16"
          >
            <path
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M2.75 12.25h10.5m-10.5-4h10.5m-10.5-4h10.5"
            />
          </svg>
        </label>
        <ul
          tabIndex={0}
          className="menu-compact dropdown-content menu rounded-box mt-3 w-52 bg-neutral p-2 shadow-md shadow-black/50"
        >
          {links.map((link) => (
            <li key={link.href}>
              <NavLink
                href={link.href}
                title={link.title}
                selected={router.pathname === `${link.href}`}
                className={`${
                  router.pathname === `${link.href}` ? "bg-primary" : ""
                } block text-lg md:hidden`}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
