const ApplePodcastsIcon = () => {
  return (
    <svg
      className="fill-current"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="48px"
      height="48px"
    >
      <path d="M18,3H6C4.346,3,3,4.346,3,6v12c0,1.654,1.346,3,3,3h12c1.654,0,3-1.346,3-3V6C21,4.346,19.654,3,18,3z M13.005,18.279	C12.825,18.797,12.331,19,12,19s-0.825-0.203-1.005-0.721c-0.18-0.518-0.495-2.311-0.495-3.513c0-1.202,1.064-1.266,1.5-1.266	s1.5,0.065,1.5,1.266C13.5,15.968,13.184,17.761,13.005,18.279z M10.75,11.25c0-0.69,0.56-1.25,1.25-1.25s1.25,0.56,1.25,1.25	S12.69,12.5,12,12.5S10.75,11.94,10.75,11.25z M12,8.5c-1.654,0-3,1.346-3,3c0,0.804,0.322,1.532,0.839,2.071	c-0.177,0.296-0.282,0.647-0.316,1.045C8.602,13.882,8,12.766,8,11.5c0-2.206,1.794-4,4-4s4,1.794,4,4	c0,1.266-0.602,2.382-1.522,3.116c-0.034-0.397-0.14-0.749-0.316-1.045C14.678,13.032,15,12.304,15,11.5C15,9.846,13.654,8.5,12,8.5	z M14.333,17.028c0.052-0.396,0.096-0.804,0.125-1.201C15.968,14.965,17,13.359,17,11.5c0-2.757-2.243-5-5-5s-5,2.243-5,5	c0,1.859,1.032,3.465,2.542,4.327c0.03,0.397,0.073,0.805,0.125,1.201C7.514,16.116,6,13.982,6,11.5c0-3.309,2.691-6,6-6	s6,2.691,6,6C18,13.982,16.486,16.116,14.333,17.028z" />
    </svg>
  );
};

export default ApplePodcastsIcon;
