import Link from "next/link";

interface NavLinkProps {
  href: string;
  title: string;
  className: string;
  selected?: boolean;
}

const NavLink: React.FC<NavLinkProps> = ({
  href,
  title,
  className,
  selected,
}) => {
  return (
    <Link
      href={href}
      title={`Navigate to ${title}`}
      className={`rounded-lg text-accent transition-all duration-300 hover:bg-slate-100/50 hover:text-accent-focus active:text-accent-content ${className} ${
        selected ? "underline underline-offset-8" : ""
      }`}
    >
      {title}
    </Link>
  );
};

export default NavLink;
