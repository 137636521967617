import Head from "next/head";
import { env } from "~/env.mjs";

export interface PodHeadProps {
  title?: string;
  description?: string;
  color?: string;
}

const PodHead: React.FC<PodHeadProps> = ({
  title,
  description = "A podcast about summer camp and the stories we love to tell",
  color = "#fb923c",
}) => {
  const titleFormat = (titleName?: string) =>
    `${
      titleName ? `${titleName} - ` : ""
    }This One Time At Summer Camp | A Podcast`;
  return (
    <Head>
      <title>{titleFormat(title)}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width,initial-scale=1.0" />
      <meta name="theme-color" content={color} />
      <link rel="canonical" href={env.NEXT_PUBLIC_APP_URL} />
      <meta name="description" content={description} />
      <meta property="og:title" content={titleFormat(title)} />
      <meta property="og:description" content={description} />
      <meta
        property="og:image"
        content={`${env.NEXT_PUBLIC_APP_URL}/api/og-image?title=${
          title ? title : ""
        }`}
      />
      <meta name="twitter:title" content={titleFormat(title)} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content={`${env.NEXT_PUBLIC_APP_URL}/api/og-image?title=${
          title ? title : ""
        }`}
      />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="robots" content="all" />
    </Head>
  );
};

export default PodHead;
