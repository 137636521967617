import Link from "next/link";

const ListenNowBtn: React.FC = () => {
  return (
    <Link
      href="episodes"
      title="See more episodes"
      className="btn-primary btn shadow-lg hover:shadow"
    >
      Listen Now
    </Link>
  );
};

export default ListenNowBtn;
