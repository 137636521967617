import ApplePodcastsIcon from "./UI/icons/ApplePodcastsIcon";
import InstagramIcon from "./UI/icons/InstagramIcon";
import SpotifyIcon from "./UI/icons/SpotifyIcon";

interface FooterProps {
  full?: boolean;
}

const Footer: React.FC<FooterProps> = ({ full }) => {
  const DateTime = new Date().getFullYear();

  return (
    <footer className="footer items-center bg-base-100 p-4 text-base-content">
      <div className="flex grid-flow-col flex-col items-start">
        <p className="font-bold">Copyright © {DateTime} - All right reserved</p>
        <div>
          <p className="text-xs">
            Made with love by{" "}
            <a
              href="https://nickleslie.dev"
              title="NickLeslie.dev"
              target="_blank"
              rel="noreferrer"
              className="link-primary link"
            >
              nickleslie.dev
            </a>
          </p>
          {full && (
            <p className="text-xs">
              Low-poly campground model graciously provided by{" "}
              <a
                href="https://sketchfab.com/3d-models/low-poly-camping-c205ff73008e4197bf0ce1ff214b28f6"
                title="Sketch Fab Model"
                target="_blank"
                rel="noreferrer"
                className="link-primary link"
              >
                tolotedesign @ sketchfab.com
              </a>{" "}
              under a Creative Commons Attribution license.
            </p>
          )}
        </div>
      </div>
      <div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
        <a
          href="https://www.instagram.com/thisonetimeatsummercamppod"
          title="See This One Time At Summer on Instagram"
          target="_blank"
          rel="noreferrer"
          className="hover:animate-pulse"
        >
          <InstagramIcon />
        </a>
        <a
          href="https://podcasts.apple.com/us/podcast/this-one-time-at-summer-camp/"
          title="Listen to This One Time At Summer Camp on Apple Podcasts"
          target="_blank"
          rel="noreferrer"
          className="hover:animate-pulse"
        >
          <ApplePodcastsIcon />
        </a>
        <a
          href="https://open.spotify.com/show/5sTfLG6GhzJ1SYDY3kWLMR"
          title="Listen to This One Time At Summer Camp on Spotify"
          target="_blank"
          rel="noreferrer"
          className="hover:animate-pulse"
        >
          <SpotifyIcon />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
